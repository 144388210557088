import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'underscore';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-artist-page',
  templateUrl: './artist-page.component.html',
  styleUrls: ['./artist-page.component.css'],
  providers: [ApiService]
})
export class ArtistPageComponent implements OnInit {
  public artist: any = {};
  public year = (new Date()).getFullYear();
  public releases: any[] = [];
  public upcomingReleases: any[] = [];
  public publishedReleases: any[] = [];
  public moment = moment;
  public defaultImage = 'https://indiefy.net/static/img/img-placeholder-dark.jpg';

  constructor(
    private api: ApiService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    let artistId = this.activeRoute.snapshot.params.artist;

    if (!artistId) {
        if (location.href.match(/localhost/)) {
            artistId = 'agnostic';
        } else {
            return location.href = 'https://indiefy.net/';
        }
    }

    this.api.getArtist({ key: artistId })
      .subscribe(res => {
        this.artist = res.json();
        this.artist.indiefyPage = `https://music.indiefy.net/artist/${this.artist.key}`;
        let docTitle = `Listen to ${this.artist.name} | Indiefy`;

        if (!this.artist.bio || (this.artist.bio && this.artist.bio.length < 50)) {
          this.artist.user = this.artist.user || {};
          this.artist.bio = this.generateArtistBio(this.artist.name, this.artist.user.country, this.artist.main_genre, this.artist.style);
        }

        window.document.title = docTitle;
        document.querySelector('meta[property="og:title"]')['content'] = docTitle;
        document.querySelector('meta[name="twitter:title"]')['content'] = docTitle;
        document.getElementsByTagName('meta')['description'].content = this.artist.bio;
        document.querySelector('meta[property="og:description"]')['content'] = this.artist.bio;
        document.querySelector('meta[name="twitter:description"]')['content'] = this.artist.bio;
        document.getElementById('imagemeta')['content'] = this.artist.cover_image;
        document.querySelector('meta[property="og:image"]')['content'] = this.artist.cover_image;
        document.querySelector('meta[property="og:url"]')['content'] = location.href;
        document.querySelector('meta[name="twitter:image"]')['content'] = this.artist.cover_image;

        if (this.artist.facebook && !this.artist.facebook.match(/^http/)) {
            this.artist.facebook = 'https://facebook.com/' + this.artist.facebook;
        }

        if (this.artist.instagram && !this.artist.instagram.match(/^http/)) {
            this.artist.instagram = 'https://instagram.com/' + this.artist.instagram;
        }

        if (this.artist.twitter && !this.artist.twitter.match(/^http/)) {
            this.artist.twitter = 'https://twitter.com/' + this.artist.twitter;
        }

        this.api.getReleases({ artistId: this.artist._id }).subscribe(res2 => {
          this.releases = res2.json().items;
          this.publishedReleases = _.filter(this.releases, item => item.status === 3);
          this.publishedReleases = _.sortBy(this.publishedReleases, '-release_date');
          this.upcomingReleases = _.filter(this.releases, item => item.status === 2);
        });

        setTimeout(() => {
          this.init();
          // @ts-ignore: Custom Variable
          window.prerenderReady = true;
        }, 1000);
      }, (error) => {
        if (artistId && artistId.toLowerCase() !== 'indiefy') {
          alert('Artist not found');
          location.href = 'https://indiefy.net?from=indiefy-me-404';
        } else {
          location.href = 'https://indiefy.net?from=indiefy-me-indiefy';
        }
      });
  }

  generateArtistBio(artistName, country, mainGenre, style) {
    const bioVariations = [
      `${artistName} is a talented ${country} artist known for their unique blend of ${mainGenre} with a touch of ${style}. With their captivating melodies and heartfelt lyrics, they have captivated audiences worldwide.`,
      `${artistName} hails from ${country} and is a rising star in the ${mainGenre} scene. Their signature style combines elements of ${style} with infectious beats, creating a sound that is both refreshing and irresistible.`,
      `Originating from ${country}, ${artistName} is a force to be reckoned with in the world of ${mainGenre}. Their music is characterized by its innovative fusion of ${style} and a profound lyrical depth that resonates with listeners on a profound level.`,
      `${artistName}, the ${country} sensation, is redefining the ${mainGenre} landscape with their distinctive sound. Their music effortlessly blends elements of ${style} with powerful vocals, creating an immersive and captivating experience.`,
      `${artistName} is an accomplished ${mainGenre} artist from ${country} known for their dynamic performances and genre-defying style. With influences ranging from ${style} to various musical traditions, their music is a true testament to artistic exploration.`,
      `With a vibrant sound that reflects the rich cultural heritage of ${country}, ${artistName} is making waves in the ${mainGenre} scene. Their music effortlessly combines elements of ${style} with infectious rhythms, creating a truly unique sonic experience.`,
      `Hailing from ${country}, ${artistName} is a rising star in the ${mainGenre} realm. Their music showcases a masterful fusion of ${style} and ${mainGenre} elements, resulting in a captivating and innovative sonic tapestry.`,
      `${artistName}, the ${country} prodigy, has taken the ${mainGenre} world by storm with their exceptional talent and distinctive style. Their music embraces elements of ${style} while pushing the boundaries of the ${mainGenre} genre.`,
      `Enigmatic and visionary, ${artistName} has emerged as a leading figure in the ${mainGenre} scene. With their groundbreaking approach that blends ${style} with ${mainGenre}, they continue to push the boundaries of musical innovation.`,
      `Drawing inspiration from their ${country} roots, ${artistName} crafts evocative music that transcends genres. Their unique sound fuses ${mainGenre} with elements of ${style}, resulting in a truly mesmerizing auditory experience.`,
      `${artistName} is an acclaimed ${mainGenre} artist from ${country} known for their genre-bending compositions. With their bold experimentation and incorporation of ${style} influences, they have forged a distinctive sound that sets them apart.`,
    ];

    const bioVariationsWithoutStyle = [
      `${artistName} is a talented artist from ${country} known for their captivating music in the ${mainGenre} genre. With their unique sound and heartfelt lyrics, they have gained a loyal following worldwide.`,
      `Hailing from ${country}, ${artistName} is an emerging artist in the ${mainGenre} scene. Their music resonates with audiences through their innovative approach and a distinct blend of influences.`,
      `${artistName} is an accomplished ${mainGenre} artist from ${country} known for their powerful performances and soulful compositions. Their music transcends genres and connects with listeners on a deep emotional level.`,
      `With a diverse range of influences, ${artistName} brings a fresh perspective to the ${mainGenre} genre. Originating from ${country}, their music is a blend of captivating melodies and thought-provoking lyrics.`,
      `Originating from ${country}, ${artistName} is a rising star in the ${mainGenre} scene. Their music showcases their versatility and ability to connect with audiences through their heartfelt lyrics and infectious melodies.`,
      `${artistName}, the talented artist from ${country}, is making waves in the ${mainGenre} genre. With their dynamic sound and authentic storytelling, they have captured the attention of music enthusiasts globally.`,
      `Embracing their ${country} roots, ${artistName} delivers a unique and compelling sound in the ${mainGenre} genre. Their music reflects their diverse influences and resonates with listeners through its captivating energy.`,
      `With a passion for creating music that pushes boundaries, ${artistName} is a trailblazer in the ${mainGenre} scene. Hailing from ${country}, they infuse their music with innovation and a fearless artistic vision.`,
    ];

    if (style) {
      const randomIndex = Math.floor(Math.random() * bioVariations.length);
      return bioVariations[randomIndex];
    } else {
      const randomIndex = Math.floor(Math.random() * bioVariationsWithoutStyle.length);
      return bioVariationsWithoutStyle[randomIndex];
    }
  }

  getUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getInstagramUser() {
    if (this.artist && this.artist.instagram) {
      return this.artist.instagram.split('/').pop();
    }
  }

  init() {
  }

}
