import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiURL = '';

  constructor(private http: Http) {
    if (window.location.hostname.match(/127.0.0.1|localhost/)) {
      this.apiURL = 'http://localhost:3000/v1/';
    } else {
      this.apiURL = 'https://indiefy.net/v1/';
    }
  }

  getReleases({ artistId }) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const options = new RequestOptions({ withCredentials: true, headers: headers });
    const req = this.http.get(`${this.apiURL}api/releases?artist=${artistId}`, options);

    return req;
  }

  getArtist({ key }) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const options = new RequestOptions({ withCredentials: true, headers: headers });
    const req = this.http.get(`${this.apiURL}api/artists/${key}?from_me=1`, options);

    return req;
  }
}
